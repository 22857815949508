.brands {
  background-image: url("../img/background4.jpg");
  background-size: cover;
  background-color: #ffffff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  /* flex-wrap: wrap; */
  height: 100vh;
  overflow: hidden;
  scroll-snap-align: start;
}

/* .brand {
  display: flex;
  justify-content: center;
} */

.brand:nth-child(1) {
  transform: translateY(-50%);
  transition-delay: 200ms;
}

.brand:nth-child(2) {
  transform: translateY(-50%);
  transition-delay: 300ms;
}

.brand:nth-child(3) {
  transform: translateY(-50%);
  transition-delay: 400ms;
}

.brand:nth-child(4) {
  transform: translateY(-50%);
  transition-delay: 500ms;
}

.brand:nth-child(5) {
  transform: translateY(-50%);
  transition-delay: 600ms;
}

.brand:nth-child(6) {
  transform: translateY(-50%);
  transition-delay: 700ms;
}

.brand.show {
  transform: translateY(0%);
}

@media (min-width: 360px) and (max-width: 1199px) {
  .brands {
    height: 900px;
    justify-content: space-evenly;
    background-position: 30% center;
  }
  .brands h1 {
    font-size: 2.5rem;
  }

  .brands-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    font-size: 1.1rem;
  }
  .brand:nth-child(1),
  .brand:nth-child(2),
  .brand:nth-child(3),
  .brand:nth-child(4),
  .brand:nth-child(5),
  .brand:nth-child(6) {
    transition-delay: 200ms;
  }

  .brand:nth-child(1) img {
    width: 240px;
    height: auto;
    margin-bottom: 30px;
  }

  .brand:nth-child(2) img {
    width: 200px;
    height: auto;
    margin-bottom: 20px;
  }

  .brand:nth-child(3) img {
    width: 200px;
    height: auto;
    margin-bottom: 20px;
  }

  .brand:nth-child(4) img {
    width: 200px;
    height: auto;
    margin-bottom: 20px;
  }

  .brand:nth-child(5) img {
    width: 200px;
    height: auto;
    margin-bottom: 10px;
  }

  .brand:nth-child(6) img {
    width: 200px;
    height: auto;
  }
}

@media (min-width: 1200px) {
  .brands {
    height: 100vh;
    justify-content: center;
  }
  .brands h1 {
    font-size: 2.4vw;
    margin-bottom: 2vw;
  }

  .brands-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .brand:nth-child(1) img {
    width: 17vw;
    height: auto;
    margin-right: 1.5vw;
  }

  .brand:nth-child(2) img {
    width: 13vw;
    height: auto;
    margin-right: 1.5vw;
  }

  .brand:nth-child(3) img {
    width: 12vw;
    height: auto;
    margin-right: 1.5vw;
  }

  .brand:nth-child(4) img {
    width: 15vw;
    height: auto;
    margin-right: 1.5vw;
  }

  .brand:nth-child(5) img {
    width: 14vw;
    height: auto;
    margin-right: 1.5vw;
  }

  .brand:nth-child(6) img {
    width: 15vw;
    height: auto;
  }
}
