.home {
  display: flex;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  color: #fff;
  background-image: url("../img/background.jpg");
  background-size: cover;
  scroll-snap-align: start;
}

.home img {
  border-radius: 1vw;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.home-snippet h1 {
  transform: translateX(50%);
  transition: all 1s;
}

.home-snippet.show h1 {
  transform: translateX(0);
}

.home-snippet p {
  opacity: 0;
  transform: translateX(50%);
  transition: all 1s;
  transition-delay: 0.2s;
}

.home-snippet.show p {
  opacity: 1;
  transform: translateX(0);
}

@media (min-width: 360px) and (max-width: 575px) {
  .home h1 {
    font-size: 2.5rem;
  }
  .home p {
    font-size: 1.1rem;
  }
  .home {
    padding-left: 5vw;
    padding-right: 5vw;
    background-position: 57% center;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .home h1 {
    font-size: 2.5rem;
  }
  .home p {
    font-size: 1.1rem;
  }

  .home {
    padding-left: 5vw;
    padding-right: 5vw;
    background-position: 52% center;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .home h1 {
    font-size: 2.5rem;
  }
  .home p {
    font-size: 1.1rem;
  }
  .home {
    padding-left: 5vw;
    padding-right: 20%;
    background-position: 30% center;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home h1 {
    font-size: 2.5rem;
  }
  .home p {
    font-size: 1.1rem;
  }
  .home {
    padding-left: 5vw;
    padding-right: 30%;
  }
}

@media (min-width: 1200px) {
  .home h1 {
    font-size: 2.5vw;
  }
  .home p {
    font-size: 1.2vw;
  }
  .home {
    padding-left: 15vw;
    padding-right: 35%;
  }
}
