.our-accessories {
  background-color: #8dabad;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  position: relative;
  overflow: hidden;
  scroll-snap-align: start;
}

.accessory-container {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  opacity: 0;
  filter: blur(3px);
  transition: all 0.5s;
}

.accessory-image img {
  border-radius: 1vw;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.accessory-container.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0%);
}

@media (min-width: 360px) and (max-width: 575px) {
  .our-accessories {
    height: 1150px;
  }
  .our-accessories h1 {
    font-size: 2.5rem;
  }

  .accessory-container {
    flex-direction: column;
    font-size: 1.1rem;
    margin-bottom: 10px;
    margin-left: 15vw;
    margin-right: 15vw;
  }

  .accessory:nth-child(1) {
    margin-bottom: 10px;
  }

  .accessory:nth-child(2) {
    order: -1;
    margin-bottom: 10px;
  }

  .accessory:nth-child(3) {
    margin-bottom: 10px;
  }

  .accessory-container:nth-child(1) {
    transform: translateX(-50%);
    transition-delay: 100ms;
  }

  .accessory-container:nth-child(2) {
    transform: translateX(-50%);
    transition-delay: 200ms;
  }

  .accessory-container:nth-child(3) {
    transform: translateX(-50%);
    transition-delay: 300ms;
  }

  .accessory-container.show:nth-child(1),
  .accessory-container.show:nth-child(2),
  .accessory-container.show:nth-child(3) {
    transform: translateY(0);
  }

  .accessory-image img {
    max-height: 180px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .our-accessories {
    height: 1350px;
  }
  .our-accessories h1 {
    font-size: 2.5rem;
  }

  .accessory-container {
    flex-direction: column;
    font-size: 1.2rem;
    margin-bottom: 10px;
    margin-left: 20vw;
    margin-right: 20vw;
  }

  .accessory:nth-child(1) {
    margin-bottom: 10px;
  }

  .accessory:nth-child(2) {
    order: -1;

    margin-bottom: 10px;
  }

  .accessory:nth-child(3) {
    margin-bottom: 10px;
  }

  .accessory-container:nth-child(2),
  .accessory-container:nth-child(3),
  .accessory-container:nth-child(4) {
    transform: translateX(-50%);
    transition-delay: 100ms;
  }

  .accessory-container.show:nth-child(2),
  .accessory-container.show:nth-child(3),
  .accessory-container.show:nth-child(4) {
    transform: translateY(0);
  }

  .accessory-image img {
    max-height: 230px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .our-accessories {
    height: 1000px;
  }
  .our-accessories h1 {
    font-size: 2.5rem;
  }

  .accessory-container {
    flex-direction: row;
    font-size: 1.2rem;
    margin-bottom: 10px;
    margin-left: 5vw;
    margin-right: 5vw;
  }
  .accessory:nth-child(1) {
    margin-right: 10px;
  }

  .accessory:nth-child(2) {
    margin-left: 10px;
  }

  .accessory:nth-child(3) {
    margin-right: 10px;
  }

  .accessory-container:nth-child(1),
  .accessory-container:nth-child(3) {
    transform: translateX(-50%);
    transition-delay: 100ms;
  }
  .accessory-container:nth-child(2) {
    transform: translateX(50%);
    transition-delay: 100ms;
  }

  .accessory-container.show:nth-child(1),
  .accessory-container.show:nth-child(2),
  .accessory-container.show:nth-child(3) {
    transform: translateY(0);
  }

  .accessory-image img {
    max-height: 250px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .our-accessories {
    height: 100vh;
  }
  .our-accessories h1 {
    font-size: 2.3rem;
  }

  .accessory-container {
    flex-direction: row;
    font-size: 1.1rem;
    margin-bottom: 10px;
    margin-left: 20vw;
    margin-right: 20vw;
  }
  .accessory:nth-child(1) {
    margin-right: 10px;
  }

  .accessory:nth-child(2) {
    margin-left: 10px;
  }

  .accessory:nth-child(3) {
    margin-right: 10px;
  }

  .accessory-container:nth-child(1),
  .accessory-container:nth-child(3) {
    transform: translateX(-50%);
    transition-delay: 100ms;
  }
  .accessory-container:nth-child(2) {
    transform: translateX(50%);
    transition-delay: 100ms;
  }

  .accessory-container.show:nth-child(1),
  .accessory-container.show:nth-child(2),
  .accessory-container.show:nth-child(3) {
    transform: translateY(0);
  }

  .accessory-image img {
    max-height: 150px;
  }
}

@media (min-width: 1200px) {
  .our-accessories {
    height: 100vh;
  }
  .our-accessories h1 {
    font-size: 2.4vw;
  }

  .accessory-container {
    flex-direction: row;
    font-size: 1.2vw;
    margin-bottom: 10px;
    margin-left: 25vw;
    margin-right: 25vw;
  }
  .accessory:nth-child(1) {
    margin-right: 10px;
  }

  .accessory:nth-child(2) {
    margin-left: 10px;
  }

  .accessory:nth-child(3) {
    margin-right: 10px;
  }

  .accessory-container:nth-child(1),
  .accessory-container:nth-child(3) {
    transform: translateX(-50%);
    transition-delay: 100ms;
  }

  .accessory-container:nth-child(2) {
    transform: translateX(50%);
    transition-delay: 100ms;
  }

  .accessory-container.show:nth-child(1),
  .accessory-container.show:nth-child(2),
  .accessory-container.show:nth-child(3) {
    transform: translateY(0);
  }

  .accessory-image img {
    max-height: 11vw;
  }
}
