.contact {
  height: 100vh;
  background-image: url("../img/contact.jpg");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
}

.contact-container {
  color: rgb(179, 196, 211);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(30, 30, 30);
  border-radius: 10px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
}

.map {
  display: flex;
}

.contact-container p,
.contact-container a {
  text-decoration: none;
  color: #fff;
}

@media (min-width: 360px) and (max-width: 575px) {
  .contact {
    height: 650px;
  }
  .map iframe {
    width: 320px;
    height: 185px;
    margin-bottom: 5px;
  }
  .contact-container {
    flex-direction: column;
    margin-top: 90px;
    font-size: 1rem;
    width: 350px;
    height: 450px;
  }

  .contact-container p,
  .contact-container h2 {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .contact {
    height: 650px;
  }
  .map iframe {
    width: 300px;
    height: 280px;
    margin-left: 15px;
    margin-bottom: 5px;
  }
  .contact-container {
    /* flex-direction: row; */
    margin-top: 85px;
    font-size: 1.1rem;
    width: 600px;
    height: 320px;
  }

  .contact-container p,
  .contact-container h2 {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .contact {
    height: 1000px;
  }
  .map iframe {
    width: 560px;
    height: 350px;
    /* margin-left: 15px;
    margin-right: 15px; */
    margin-bottom: 5px;
  }
  .contact-container {
    flex-direction: column;
    margin-top: 85px;
    font-size: 1.1rem;
    width: 600px;
    height: 620px;
  }

  .contact-container p,
  .contact-container h2 {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .contact {
    height: 100vh;
  }
  .map iframe {
    width: 560px;
    height: 350px;
    /* margin-left: 15px;
    margin-right: 15px; */
    margin-bottom: 5px;
  }
  .contact-container {
    flex-direction: column;
    margin-top: 85px;
    font-size: 1.1rem;
    width: 600px;
    height: 620px;
  }

  .contact-container p,
  .contact-container h2 {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media (min-width: 1200px) {
  .contact {
    height: 100vh;
  }
  .map iframe {
    width: 32vw;
    height: 22vw;
    margin-right: 1vw;
  }
  .contact-container {
    margin-top: 85px;
    height: 26vw;
    width: 52vw;
    font-size: 1.05vw;
  }

  .contact-container p,
  .contact-container h2 {
    width: 15vw;
  }
}
