.why-us {
  background-image: url("../img/background2.jpg");
  background-size: cover;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  text-align: center;
  overflow: hidden;
  scroll-snap-align: start;
}

.logos {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container {
  background-color: #f0eded;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
  width: 20vw;
  height: 20vw;
  transform: translateX(-50%);
  opacity: 0;
  filter: blur(3px);
  transition: all 0.5s;
}
.icon-container {
  /* height: 40%; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(60, 60, 60);
}

.material-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.material-icon svg {
  color: rgb(130, 203, 130);
  width: 20%;
  height: 20%;
}

.logo-container:nth-child(1) {
  transition-delay: 200ms;
}

.logo-container:nth-child(2) {
  transition-delay: 400ms;
}

.logo-container:nth-child(3) {
  transition-delay: 600ms;
}

.logo-container:nth-child(4) {
  transition-delay: 800ms;
}

.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}

@media (min-width: 360px) and (max-width: 575px) {
  .why-us {
    height: 1300px;
  }
  .why-us h1 {
    font-size: 2.5rem;
  }
  .icon-container {
    height: 30%;
    border-top-left-radius: 3vw;
    border-top-right-radius: 3vw;
  }

  .icon-container svg {
    width: 20%;
    height: 20%;
  }

  .logo {
    margin: 5px;
  }

  .logo-container {
    border-radius: 3vw;
    font-size: 1rem;
    width: 17rem;
    height: 17rem;
    margin-bottom: 15px;
  }

  .logo-container:nth-child(1),
  .logo-container:nth-child(2),
  .logo-container:nth-child(3),
  .logo-container:nth-child(4) {
    transition-delay: 200ms;
  }

  .logos {
    flex-direction: column;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .why-us {
    height: 1300px;
  }
  .why-us h1 {
    font-size: 2.5rem;
  }
  .icon-container {
    height: 30%;
    border-top-left-radius: 3vw;
    border-top-right-radius: 3vw;
  }

  .icon-container svg {
    width: 20%;
    height: 20%;
  }

  .logo {
    margin: 5px;
  }

  .logo-container {
    border-radius: 3vw;
    font-size: 1rem;
    width: 20rem;
    height: 17rem;
    margin-bottom: 15px;
  }

  .logo-container:nth-child(1),
  .logo-container:nth-child(2),
  .logo-container:nth-child(3),
  .logo-container:nth-child(4) {
    transition-delay: 200ms;
  }

  .logos {
    flex-direction: column;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .why-us {
    height: 1250px;
  }
  .why-us h1 {
    font-size: 2.5rem;
  }
  .icon-container {
    height: 30%;
    border-top-left-radius: 2vw;
    border-top-right-radius: 2vw;
  }

  .icon-container svg {
    width: 20%;
    height: 20%;
  }

  .logo {
    margin: 5px;
  }

  .logo-container {
    border-radius: 2vw;
    font-size: 1rem;
    width: 23rem;
    height: 15rem;
    margin-bottom: 15px;
  }

  .logo-container:nth-child(1),
  .logo-container:nth-child(2),
  .logo-container:nth-child(3),
  .logo-container:nth-child(4) {
    transition-delay: 200ms;
  }

  .logos {
    flex-direction: column;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .why-us {
    height: 100vh;
  }
  .why-us h1 {
    font-size: 2.5rem;
  }
  .icon-container {
    height: 30%;
    border-top-left-radius: 2vw;
    border-top-right-radius: 2vw;
  }

  .icon-container svg {
    width: 20%;
    height: 20%;
  }

  .logo {
    margin: 5px;
  }

  .logo-container {
    border-radius: 2vw;
    font-size: 0.9rem;
    width: 15rem;
    height: 15rem;
    margin-left: 5px;
    margin-right: 5px;
  }

  .logos {
    flex-direction: row;
  }
}

@media (min-width: 1200px) {
  .why-us {
    height: 100vh;
  }
  .why-us h1 {
    font-size: 2.4vw;
  }
  .icon-container {
    height: 30%;
    border-top-left-radius: 2vw;
    border-top-right-radius: 2vw;
  }

  .icon-container svg {
    width: 20%;
    height: 20%;
  }

  .logo {
    margin: 5px;
  }

  .logo-container {
    border-radius: 2vw;
    font-size: 1.2vw;
    width: 21vw;
    height: 21vw;
    margin-left: 5px;
    margin-right: 5px;
  }

  .logos {
    flex-direction: row;
  }
}
