.company-logo {
  display: flex;
}

.links {
  display: flex;
  justify-content: center;
  /* scroll-snap-align: start; */
}

.navbar {
  background-color: rgb(121, 137, 140);
  position: absolute;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-bottom: 0.2vw solid #fff;
}

.navbar .links a {
  margin-left: 0.5vw;
  margin-right: 0.5vw;
  color: #fff;
  text-decoration: none;
}

.company-logo a {
  line-height: 0;
}

.navbar a:hover {
  color: #24128a;
}

@media (max-width: 768px) {
  .company-logo img {
    width: 100px;
  }
  .navbar {
    height: 90px;
    margin-top: 0;
    flex-direction: column;
  }
  .navbar a {
    margin-left: 0.3rem;
    margin-right: 0.3rem;
  }
}

@media (min-width: 769px) and (max-width: 1199px) {
  .company-logo img {
    width: 120px;
  }
  .navbar {
    height: 70px;
    margin-top: 0;
  }

  /* .navbar a {
    font-size: 1.1vw;
  } */
}

@media (min-width: 1200px) {
  .company-logo img {
    width: 9vw;
  }
  .navbar {
    height: 5vw;
    margin-top: 0;
  }

  .navbar a {
    font-size: 1.1vw;
  }
}
