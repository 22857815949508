@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

* {
  margin: 0;
  font-family: "Poppins";
}

.hidden {
  opacity: 0;
  filter: blur(3px);
  transition: all 0.5s;
}

@media (min-width: 1200px) {
  html {
    scroll-snap-type: y mandatory;
  }
}
