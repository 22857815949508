.footer {
  background-color: #1e1e1e;
  color: #fff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 35vh;
  /* font-size: 1em; */
  overflow: hidden;
  scroll-snap-align: start;
}

.footer .logo a {
  line-height: 0;
}

.footer .useful-links a {
  text-decoration: none;
  color: rgb(162, 162, 162);
}

.footer .contact-us p {
  display: block;
  text-decoration: none;
  color: rgb(162, 162, 162);
}

.footer .contact-us a {
  display: block;
  text-decoration: none;
  color: rgb(233, 233, 233);
}

@media (min-width: 360px) and (max-width: 575px) {
  .footer {
    flex-direction: column;
    height: 400px;
    justify-content: space-evenly;
    align-items: start;
  }
  .footer .logo img {
    width: 300px;
    margin-left: 3%;
  }
  .footer .useful-links {
    margin-left: 5%;
  }

  .footer .contact-us {
    margin-left: 5%;
    margin-right: 10%;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .footer {
    flex-direction: column;
    height: 400px;
    justify-content: space-evenly;
    align-items: start;
  }
  .footer .logo img {
    width: 300px;
    margin-left: 3%;
  }
  .footer .useful-links {
    margin-left: 5%;
  }

  .footer .contact-us {
    margin-left: 5%;
    margin-right: 10%;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .footer {
    flex-direction: row;
    height: 200px;
  }
  .footer .logo img {
    width: 300px;
  }

  .footer .contact-us p {
    width: 300px;
  }
}

@media (min-width: 1200px) {
  .footer {
    font-size: 1.1vw;
    flex-direction: row;
    height: 35vh;
  }
  .footer .logo img {
    width: 25vw;
  }

  .footer .contact-us p {
    width: 25vw;
  }
}
