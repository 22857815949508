.about {
  height: 100vh;
  background-image: url("../img/about.jpg");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
}

.about-container {
  color: rgb(179, 196, 211);
  /* color: rgb(194, 182, 182); */
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  /* text-align: center; */

  background-color: rgb(102, 102, 102);
  /* background-color: rgb(105, 110, 115); */
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
}

@media (min-width: 360px) and (max-width: 575px) {
  .about {
    height: 600px;
  }
  .about-container {
    margin-top: 90px;
    font-size: 0.9rem;
    width: 350px;
    height: 400px;
  }

  .about-container p {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .about {
    height: 600px;
  }
  .about-container {
    margin-top: 85px;
    font-size: 1.1rem;
    width: 550px;
    height: 400px;
  }

  .about-container p {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .about {
    height: 600px;
  }
  .about-container {
    margin-top: 70px;
    font-size: 1.1rem;
    width: 700px;
    height: 400px;
  }

  .about-container p {
    margin-left: 50px;
    margin-right: 50px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .about {
    height: 100vh;
  }
  .about-container {
    margin-top: 70px;
    font-size: 1.1rem;
    width: 800px;
    height: 400px;
  }

  .about-container p {
    margin-left: 130px;
    margin-right: 130px;
  }
}

@media (min-width: 1200px) {
  .about {
    height: 100vh;
  }
  .about-container {
    margin-top: 5vw;
    font-size: 1.2vw;
    height: 33vw;
    width: 50vw;
  }

  .about-container p {
    margin-left: 130px;
    margin-right: 130px;
    margin-left: 5vw;
    margin-right: 5vw;
  }
}
